<template>
    <main id="result" class="d-flex justify-content-center">
        <div v-if="result" v-html="result">
        </div>
    </main>
</template>
<script>
    export default {
        data: () => ({
            result: null
        }),
        async created() {
            const result = await this.$store.dispatch('asset/getQRCode', this.$route.params.id)

            this.result = result
        }
    }
</script>